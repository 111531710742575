
const translations = {
  language: "En",
  changeLanguage: "English Text",
  aboutTheCompany: "Why Authentic Indication?",
  aboutUs: "About the Company",
  email: "Email",
  password: "Password",
  passwordConfirmation: "Confirm Password",
  submitPassword: "Submit",
  forgotPassword: "Forgot Password?",
  login: "Login",
  interestedRegister: "Interested in registering?",
  aboutAIText: "Authentic Indication 株式会社 provides brokers with a system that enables IOI messaging for institutional traders via a secure, cloud-based platform. Hedge Fund and Asset Management trading teams submit encrypted IOIs directly to the platform, where the information is held in escrow until conditions specified by the submitter are satisfied.\n\nIOI submitters are connected on a bilateral basis, with simultaneous notification of both parties.  Parties are directed to a broker for execution only if they bilaterally accept the notification.\n\n Authentic Indication 株式会社 does not provide investment advice, manage customer assets, or conduct solicitation or sales of funds.",
  aboutAIText1: "Authentic Indication 株式会社 provides an IOI messaging service for institutional traders via a secure, cloud-based platform. Hedge Fund and Asset Management trading teams submit encrypted IOIs directly to the platform, where the information is held in escrow until conditions specified by the submitter are satisfied.",
  aboutAIText2: "IOI submitters are connected on a bilateral basis, with simultaneous notification of both parties. Parties are directed to a broker for execution only if they bilaterally accept the notification.",
  aboutAIText3: "Authentic Indication 株式会社 does not provide investment advice, manage customer assets, or conduct solicitation or sales of funds.",
  gordonBioText:"CEO - Gordon Niamatali has 18 years experience working for investment banks in Asia, most recently running Equity Execution Services for Citigroup in Japan, and Equity Flow Derivatives for Morgan Stanley in Japan before that. Prior to trading, Gordon spent 3 years in Finance at Lehman Brothers and Deutsche Bank, handling product control and accounting for derivative products. Gordon has a BA in economics from Cornell University, with concentrations in Japanese language and mathematics.",
  janBioText:"CTO - Jan Schiffman has over 30 years of experience in delivering commercial software products, with a focus on Fintech, BI, analytics, ML, and more recently, GenAI. He has successfully launched products as both an executive engineering and product leader in both startup and large enterprise settings, including VP of Analytics and Data Science Product Development at TIBCO and Oracle. In addition, Jan has 10 years of experience working in London and Tokyo in the fintech sector. Jan studied Computer Science at Rutgers University.",
  inCollabWith: "In collaboration with:",
  forMoreInfo: "For more information: ",
  pressRelease: "Press Release",
  pressReleaseURL: "https://www.jpx.co.jp/english/corporate/news/news-releases/1030/20250115-01.html",
  infoEmail:"info@authenticindication.com",
  myProfile: "My Profile",
  languageDropdown: "Language: ",
  contactAccountManager: "Contact Account Manager",
  logOff: "Log Off",
  terms: "Terms & Conditions",
  languageLabel: "Language",
  startStock: "Start by typing a stock to advertise...",
  bulkUpload: "Bulk Upload",
  bulkUploadConfirm: "Bulk Upload Confirmation",
  couldNotUpload: "could not be uploaded",
  reuploadCSVText: "Please review your CSV to reupload, or enter them in manually in our system.",
  upload: "Upload",
  ioiSummary: "IOI Summary",
  buy: "Buy",
  sell: "Sell",
  buyList: "B",
  sellList: "S",
  quantity: "Quantity",
  usdNotional: "USD Notional",
  jpyNotional: "JPY Notional",
  notional: "notional",
  priceConditions: "Price Conditions",
  advertisingPreferences: "Advertising Preferences",
  pathPreferences: "Path Preferences",
  pathOrder: "Path Order",
  rankedEqual: "Ranked / Equal",
  brokers: "Brokers",
  cancel: "Cancel",
  pause: "Pause",
  resume: "Resume",
  send: "Send",
  submit: "Submit",
  submitForm: "Submit",
  update: "Update",
  ioiReview: "IOI Review",
  shares: "shares",
  dragDropCSV: "Drag & drop a CSV file of stocks to advertise...",
  browseFile: "or browse files on your computer",
  advertisingTrigger: "Advertising Trigger",
  cancelTrigger: "Cancel Trigger",
  indexRelativeCancel: "Index Relative Cancel",
  tradingRange: "Trading Range",
  maxPrice: "Max Price",
  minPrice: "Min. Price",
  credibilityFilter: "Credibility Filter",
  contraTransactionType: "Contra Transaction Type",
  duration: "Duration",
  goodForTheDay: "Good for the day",
  minutes: "minutes",
  minimumQ: "Minimum Q %",
  quantityDecay: "Quantity Decay",
  setDefault: "Set Default",
  rank: "Rank",
  rankingPreference: "Ranking Preference",
  ranked: "Ranked",
  equalRank: "Equal Rank",
  none: "None",
  noneSelected: "None Selected",
  contraLocated: "Contra Located - Connect?",
  ioiDirection: "IOI Direction",
  contraIoiDirection: "Contra IOI Direction",
  commonQuantity: "Common Quantity",
  minimumQuantity: "Minimum Quantity",
  decline: "Decline",
  accept: "Accept",
  connectionAccepted: "Connection Accepted!",
  midPrice: "Mid-Price",
  connectionTime: "Connection Time",
  executingBroker: "Executing Broker",
  copyDetails: "Copy Details",
  residualAmount: "IOI Residual Amount?",
  close: "Close",
  connectionDeclined: "Connection Declined",
  contraConnectionDeclined: "Contra Declined Connection",
  locateTimeout: "Timed Out - Connection Declined",
  ok: "OK",
  logOffReset: "Log Off & Reset Demo",
  security: "Security",
  securities: "Securities",
  price: "Price",
  timestamp: "Timestamp",
  received: "Received",
  pending: "Pending",
  brokerReceived: "Received",
  brokerPending: "Pending",
  summaryScreen: "Summary Screen",
  securitiesSelected: "Securities Selected",
  copied: "Copied to Clipboard",
  copyToClipboard: "Copy line to Clipboard",
  clickCopyLine: "Click “Copy line to Clipboard” to copy these items to clipboard.",
  selectBulkAction: "Select a Bulk Action below.",
  emailSummary: "Email Summary",
  returnToMain: "Return to Main",
  timeRemaining: "Time Remaining",
  quantityRemaining: "Quantity Remaining",
  percentRemaining: "% Remaining",
  notifications: "Notifications",
  statusAlert: "Status Alert",
  downloadTemplate: "Template",
  viewOnDesktop: "Please view on desktop\n for full experience",
  matchConfirmed: "New IOI Match Confirmed",
  confirmedBy: "Confirmed By",
  statusUpdated: "Status Updated",
  acknowledged: "Acknowledged",
  IOIExpired: "IOI Expired",
  stockMove: "Stock move today",
  indexMove: "Index move today",
  genericErrorMessage: "Cannot submit advertisement.",
  outsideHoursMessage: "AI's Platform hours for [ric] are: ",
  currentlyClosed: "Currently Closed.",
  hoursOfOperation: "AI's hours of operation are: ",
  receivingIOIs: "Receiving IOI" ,
  matchmakingEngine: "Matchmaking Engine",
  on: "ON",
  off: "OFF",
  loggedOut: "You have been logged out",
  loggedOutDetails: "This account has logged in at another location. Please log back in to continue here.",
  loggedOutDetailsTimeout: "Your session has timed out. Please log back in to continue here.",
  edit: "Edit",
  accountManagerContacted: "We will contact you shortly",
  ioiCounts: "IOI Counts",
  uploads : "Uploads",
  cancels : "Cancels",
  locates : "Locates",
  accepts : "Accepts",
  declines : "Declines",
  timeouts : "Timeouts",
  triggerCancels : "Trigger Cancels",
  condPrefs : "Conditions / Preferences",
  invalidCsvFileSelected: "The file selected is not a '.csv' file. Click 'Template' for a sample file.",
  newVersionAvailable: "A new version is available. Please refresh your browser.",
  executingBrokers: "Executing Brokers",
  teamAccepted: "Locate Accepted by %s1",
  restoreIOI: "Prior Day Expired",
  restoreDate: "Expired as of %s1",

  // Transaction Types
  type: "Type",
  transactionType: "Transaction type",
  transactionTypeCASH_ONLY: "Cash only",
  transactionTypeSWAP_ONLY: "Swap only",
  transactionTypeCASH_SWAP: "Cash / Swap",

  // quantity-decay
  yes: 'Enabled',
  no: 'Disabled',

  // Statuses
  status: "Status",
  statusUNSENT: "Unsent",
  statusSENT: "Sent",
  statusPAUSED: "Paused",
  statusCANCELLED: "Cancelled",
  statusEXPIRED: "Expired",
  statusCOMPLETE: "Complete",
  statusLOCATED: "Located",
  statusUndefined: "Undefined",
  emailSent: "An email has been sent to [email]",
  passwordDoesNotMatch: "Password does not match",
  invalidExistingPassword: "Invalid existing password",
  linkExpired: "This link has expired",
  gatewayError: "Authentic Indication gateway is offline, email 'support@authenticindication.com' for assistance",

  //Profile
  profileSecurity: "Security",
  resetPassword: "Reset Password",
  currentPassword: "Current Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm new password",
  passwordResetSuccess: "Password reset successful",
  prefsUpdated: "Default settings updated",

  // First Login page
  firstLoginWelcome: "Welcome to Authentic Indication!",
  firstLoginInstructions: "Please input and confirm your new password, you will be logged out and will need to log in with your new password.",

  // Error Codes
  'ERR-001': "Invalid value for [header]",
  'ERR-002': "Line [line]: Invalid ric code.",
  //Error code 3 will be file/error specific and can have multiple results (no 1:1 translation mapping)
  'ERR-004': "Line [line]: [ric]: User or user's institution has already submitted an advertisement with this security.",
  'ERR-005': "Line [line]: [ric]: cannot submit multiple advertisements with same security.",
  'ERR-006': "Line [line]: [ric]: [direction]  [shareQuantity] shares below minimum quantity of [minimumShareQuantity] shares",
  'ERR-007': "Line [line]: [ric]: Minimum quantity of [value] is not between [minimum] and [maximum]",
  'ERR-008': "Line [line]: [ric]: Credibility hurdle of [value] is not between [minimum] and [maximum]",
  'ERR-009': "Line [line]: [ric]: Advertisement trigger offset of [value] is not between [minimum] and [maximum]",
  'ERR-010': "Line [line]: [ric]: Cancel trigger of [value] is not between [minimum] and [maximum]",
  'ERR-011': "Line [line]: [ric]: Index relative offset of [value] is not between [minimum] and [maximum]",
  'ERR-012': "Line [line]: [ric]: Outside AI Platform Hours - ",
  'ERR-013': "Line [line]: Number of data fields does not match number of headers.",
  'ERR-014': "Line [line]: [ric]: The exchange is closed today",
  'RESTORE-ERR-01': "[ric]: [direction]  [shareQuantity] shares below minimum quantity of [minimumShareQuantity] shares",
  'RESTORE-ERR-02': "[ric]: User or user's institution has already submitted an advertisement with this security.",
  'RESTORE-ERR-03': "[ric]: Outside AI Platform Hours - ",
  'RESTORE-ERR-04': "Invalid ric code",
  'RESTORE-ERR-05': "[ric]: The exchange is closed today",
  'MARKET-ERROR-001': "Cancelled, Bad Market Data",
  'MARKET-ERROR-002': "Cancelled, Bad Market Data",
  'MARKET-ERROR-003': "Cancelled, Bad Market Data",
  'MARKET-ERROR-004': "Cancelled, Inactive market",
  'MARKET-ERROR-005': "Cancelled, Inactive market",
  'MARKET-ERROR-006': "Cancelled, Inactive market",
  'MARKET-ERROR-007': "Cancelled, Malformed market",
  'MARKET-ERROR-012': "Cancelled, Bad Market Data",
  'MARKET-ERROR-013': "Cancelled, Bad Market Data",
  'CANCEL-TRIGGER-001': "Cancelled, Cancel Trigger",
  'CANCEL-TRIGGER-002': "Cancelled, Index Relative Cancel",
  'ADVERT-ERROR-001': "IOI on %s1 already exists for your Firm",
  'ADVERT-ERROR-002': "Attempt to modify canceled or located advertisement.",
  'ADVERT-ERROR-003': "Attempt to resume a non-paused advertisement.",
  'ADVERT-ERROR-004': "%s1 shares below minimum quantity of %s2",
  'ADVERT-ERROR-005': "Not possible to create residual from this advertisement",
  'ADVERT-ERROR-006': "%s1 is an invalid credibility hurdle",
  'ADVERT-ERROR-007': "Attempt to pause canceled or located advertisement.",
  'ADVERT-ERROR-008': "IOI on %s1 already exists",
  'ADVERT-ERROR-010': "The exchange for %s1 is closed today",
  'ADVERT-ERROR-011': "Please select a Broker",

  // Validations
  requiredField: 'This field is required',
  minValue: 'Min. value allowed is {min}',
  maxValue: 'Max. value allowed is {max}',
};

export default translations;
