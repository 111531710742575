import React, { useState, useEffect } from "react";
import Error from "../components/Error";
import Spinner from "../components/Spinner";
import "../styles/aboutUs.css";
import useTranslation from "../components/customHooks/Translations";
import { isMobile } from "react-device-detect";

const AboutUsScreen = (props) => {
  const translation = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Determine images based on language selection
  const imageList = translation.language === "Jp"
    ? ["Traditional IOIs (JP).png", "Dark Pool Problem (JP).png", "IOI Solution (JP).png"]
    : ["Traditional IOIs (EN).png", "Dark Pool Problem (EN).png", "IOI Solution (EN).png"];

  // Automatically change images every 5 seconds for mobile carousel
  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
      }, 5000);
      return () => clearInterval(interval); // Cleanup function
    }
  }, [imageList]); // Update when language changes

  return (
    <div
      className={isMobile ? "mobile-about-us-container" : "desktop-about-us-container"}
      style={props?.mobileMenu ? { boxShadow: "3000px 3000px rgba(0,0,0,.70) inset", filter: "brightness(0.3)" } : null}
    >
      {/* Video Section */}
      <div className="video-container">
        <video className="about-us-video" controls playsInline>
          <source src="Authentic Indication - English.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Conditional Rendering: Desktop (Side by Side) vs Mobile (Carousel) */}
      {isMobile ? (
        // Mobile: Carousel
        <div className="carousel-container">
          {imageList.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Rotating Carousel - ${translation.language}`}
              className={`carousel-image ${index === currentImageIndex ? "active" : ""}`}
            />
          ))}
        </div>
      ) : (
        // Desktop: Side by Side Images
        <div className="image-grid">
          {imageList.map((image, index) => (
            <img key={index} src={image} alt={`Desktop Image - ${translation.language}`} className="grid-image" />
          ))}
        </div>
      )}

      {/* Text Section */}
      <div className="text-section">
        <div className="about-us-header">{translation.aboutTheCompany}</div>
        <div className="about-us-text">{translation.aboutAIText}</div>
      </div>
      {/* Add two line breaks before the bio section on mobile */}
      {isMobile && (
        <>
          <br />
          <br />
        </>
      )}

     {/* New Section with Gordon and Jan */}
      <div className="bio-section">
        <div className="bio-container">
          {/* Gordon */}
          <div className="bio-item">
            <img 
              src={isMobile ? "Gordon250px.png" : "Gordon.png"} 
              alt="Gordon" 
              className="bio-image" 
            />
            <p className="bio-text">{translation.gordonBioText}</p>
          </div>
          
          {/* Jan */}
          <div className="bio-item">
            <img 
              src={isMobile ? "Jan250px.png" : "Jan.png"} 
              alt="Jan" 
              className="bio-image" 
            />
            <p className="bio-text">{translation.janBioText}</p>
          </div>
        </div>
      </div>
      <div className="for-more-information">
        <p className="for-more-information-text">{translation.forMoreInfo}<a href={"mailto:"+translation.infoEmail}>{translation.infoEmail}</a></p>
      </div>
      <div className="footer-watermark">© 2025 Authentic Indication.</div>
    </div>
  );
};

export default AboutUsScreen;