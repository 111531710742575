const translations = {
  language: "Jp",
  changeLanguage: "Japanese Text",
  aboutTheCompany: "Authentic Indication について",
  aboutUs: "弊社サービスの概",
  email: "メールアドレス",
  password: "パスワード",
  passwordConfirmation: "パスワードを認証する",
  submitPassword: "送信",
  forgotPassword: "パスワードを忘れた方",
  login: "サインイン",
  interestedRegister: "アカウントを持っていませんか？",
  aboutAIText: "Authentic Indication 株式会社は、安全なクラウドベースのプラットフォームを介して機関投資家向けのIOI（Indication of Interest）メッセージングを可能とするシステムをブローカーに提供します。ヘッジファンドやアセットマネジメントのトレーディングチームは、暗号化されたIOIを直接プラットフォームに送信します。プラットフォームに送信されたIOIに関する情報は、送信者の指定した条件が満たされるまで他に通知されることはありません。\n\nIOI の引合わせは二者間でなされます。引合わせ先が確認されると双方の送信者に対し同時に通知されます。双方の送信者がともに通知を承諾すると、取引を執行するようブローカーに指示がなされます。\n\nAuthentic Indication 株式会社は、投資アドバイスの提供、顧客資産の管理、資金の勧誘または販売を行うものではありません。",
  aboutAIText1: "Authentic Indication 株式会社は、安全なクラウドベースのプラットフォームを介して機関投資家向けのIOI（Indication of Interest）メッセージングサービスを提供します。ヘッジファンドやアセットマネジメントのトレーディングチームは、暗号化されたIOIを直接プラットフォームに送信します。プラットフォームに送信されたIOIに関する情報は、送信者の指定した条件が満たされるまで他に通知されることはありません。",
  aboutAIText2: "IOI の引合わせは二者間でなされます。引合わせ先が確認されると双方の送信者に対し同時に通知されます。双方の送信者がともに 通知を承諾すると、取引を執行するようブローカーに指示が なされます。",
  aboutAIText3: "Authentic Indication 株式会社は、投資アドバイスの提供、 顧客資産の管理、資金の勧誘または販売を行うものでは ありません。",
  gordonBioText:"CEO - ゴードン ニアマタリはアジアを拠点に複数の大手投資銀行にて18年間勤務。前職では日本のシティグループにてエクイティ・エクセキューション・サービス部長としてチームを統括。またシティグループへの異動前は、モルガン・スタンレーMUFG証券にてエクイティ・フロー・デリバティブに係るトレーディング業務に従事。トレーディング業務に従事する以前は、リーマン・ブラザーズ証券及びドイツ証券の財務部門にてプロダクトコントロールとデリバティブ商品の会計処理に従事。米国コーネル大学経済学部卒業。副専攻は日本語と数学",
  janBioText:"CTO - ジャン シフマンは、商用ソフトウェア製品の提供において30年以上の経験を持ち、特にBI(ビジネスインテリジェンス)、アナリティクス、ML(機械学習)、そして最近ではGenAI(生成AI)に注力しています。彼は、スタートアップから大企業に至るまで、エンジニアリングおよびプロダクトリーダー、さらには経営者とて製品を成功裏に立ち上げてきました。TIBCOやOracleでは、アナリティクスおよびデータサイエンスのプロダクト開発のVPを務めた経験もあります。また、ジャンはロンドンと東京でフィンテック分野において10年間の経験を持っています。彼はラトガース大学でコンピュータサイエンスを学びました。",
  inCollabWith: "In collaboration with:",
  forMoreInfo: "問い合わせ先：",
  pressRelease: "プレスリリース",
  pressReleaseURL: "https://www.jpx.co.jp/corporate/news/news-releases/1030/20250115-01.html",
  infoEmail:"info@authenticindication.com",
  myProfile: "プロフィール",
  languageDropdown: "言語: ",
  contactAccountManager: "アカウント マネージャーに連絡する",
  logOff: "ログオフ",
  terms: "利用規約",
  languageLabel: "言語",
  startStock: "銘柄を入力",
  bulkUpload: "一括アップロード",
  bulkUploadConfirm: "一括アップロードの確認",
  upload: "アップロード",
  couldNotUpload: "のアップロードができない",
  reuploadCSVText: "CSVを見直して再アップロードしていただくか、弊社システムで手入力をしてください。",
  ioiSummary: "IOI 概要",
  buy: "買い",
  buyList: "買",
  sell: "売り",
  sellList: "売",
  quantity: "株数",
  usdNotional: "USD想定元本",
  jpyNotional: "JPY想定元本",
  notional: "想定元本",
  priceConditions: "価格の条件",
  advertisingPreferences: "相手の条件",
  pathPreferences: "ブローカー順位",
  pathOrder: "Path Order",
  rankedEqual: "順位付け / 順位なし",
  brokers: "ブローカー",
  cancel: "キャンセル",
  pause: "一時停止",
  resume: "再開する",
  send: "送信",
  submit: "アップロード",
  submitForm: "送信",
  update: "アップデート",
  ioiReview: "IOIレビュー",
  shares: "株数",
  dragDropCSV: "CSVファイルをドラッグアンドドロップで作成、",
  browseFile: "またはコンピューターのファイルを参照する",
  advertisingTrigger: "開始トリガー価格",
  cancelTrigger: "キャンセルトリガー価格",
  indexRelativeCancel: "指数相対キャンセル",
  tradingRange: "取引範囲",
  maxPrice: "最高価格",
  minPrice: "最低価格",
  credibilityFilter: "信頼性フィルター",
  contraTransactionType: "相手の取引の種類",
  duration: "時間",
  goodForTheDay: "終日",
  minutes: "分",
  minimumQ: "最小株数％",
  quantityDecay: "株数自動削減",
  setDefault: "この条件を保存",
  rank: "順位",
  rankingPreference: "Ranking Preference",
  ranked: "順位付け",
  equalRank: "順位なし",
  none: "無し",
  noneSelected: "選択する",
  contraLocated: "相手あり。本通知を承諾しますか？",
  ioiDirection: "IOI方向",
  contraIoiDirection: "相手IOI方向",
  commonQuantity: "共通株数",
  minimumQuantity: "最小株数",
  decline: "却下",
  accept: "承諾",
  connectionAccepted: "両者が承諾！",
  midPrice: "中間価格",
  connectionTime: "引き合わせ時刻",
  executingBroker: "ブローカー",
  copyDetails: "詳細をコピー",
  residualAmount: "残りの株数をIOI？",
  close: "閉じる",
  connectionDeclined: "却下しました",
  contraConnectionDeclined: "相手に却下されました",
  locateTimeout: "タイムアウト 却下しました",
  ok: "OK",
  logOffReset: "Log Off & Reset Demo",
  security: "銘柄",
  securities: "銘柄",
  price: "価格",
  timestamp: "タイムスタンプ",
  received: "頂き",
  pending: "未解決",
  brokerReceived: "完了",
  brokerPending: "保留中",
  summaryScreen: "概要画面",
  securitiesSelected: "選択された証券",
  copied: "クリップボードにコピーしました",
  copyToClipboard: "行をクリップボードにコピーする",
  clickCopyLine: "「行をクリップボードにコピーする」をクリックすると、これらの項目がクリップボードにコピーされます。",
  selectBulkAction: "以下から一括して選択してください。",
  emailSummary: "概要をメールする",
  returnToMain: "メインに戻る",
  timeRemaining: "残存時間",
  quantityRemaining: "残量",
  percentRemaining: "残存%",
  notifications: "通知",
  statusAlert: "ステータス通知",
  downloadTemplate: "テンプレート",
  viewOnDesktop: "デスクトップでご覧ください\n をご覧ください。",
  matchConfirmed: "新しいIOIの試合が決定",
  confirmedBy: "確認者",
  statusUpdated: "ステータス更新",
  acknowledged: "認められた",
  IOIExpired: "IOI 期限切れ",
  stockMove: "今日の株の動き",
  indexMove: "今日の指数の動き",
  genericErrorMessage: "広告を送信できません。",
  outsideHoursMessage: "AIの[ric]のプラットフォームアワーは以下の通り: ",
  currentlyClosed: "現在休業中。",
  hoursOfOperation: "AIの営業時間は以下の通り： ",
  receivingIOIs: "IOIを受け取る" ,
  matchmakingEngine: "マッチメイキング・エンジン",
  on: "ON",
  off: "OFF",
  accountManagerContacted: "まもなく連絡させていただきます",
  ioiCounts: "IOI 概要",
  uploads : "アップロード",
  cancels : "キャンセル",
  locates : "相手あり",
  accepts : "承諾",
  declines : "却下",
  timeouts : "タイムアウト",
  triggerCancels : "トリガーのキャンセル",
  condPrefs : "条件やブローカー順位",
  invalidCsvFileSelected: "選択されたファイルは「.csv」ではありません。サンプルファイルを取得するには「テンプレート」をクリックしてください。",
  newVersionAvailable: "新しいバージョンが利用可能です。ブラウザをリフレッシュしてください。",
  executingBrokers: "ブローカー",
  teamAccepted: "%s2%s1がマッチを承諾しました",
  restoreIOI: "前日の期限切れ",
  restoreDate: "%s1の期限切れ",

  // Transaction Types
  type: "種類",
  transactionType: "取引の種類",
  transactionTypeCASH_ONLY: "株",
  transactionTypeSWAP_ONLY: "スワップ",
  transactionTypeCASH_SWAP: "株および/またはスワップ",
  loggedOut: "ログアウトされました",
  loggedOutDetails: "このアカウントは別の場所でログインしました。 ログインしてください。",
  loggedOutDetailsTimeout: "セッションがタイムアウトしました。 ログインしてください。",
  edit: "編集",

  // quantity-decay
  yes: "有効",
  no: "無効",

  // Statuses
  status: "ステータス",
  statusUNSENT: "未送信",
  statusSENT: "送信済み",
  statusPAUSED: "休止",
  statusCANCELLED: "キャンセル",
  statusEXPIRED: "期限切れ",
  statusCOMPLETE: "完了",
  statusLOCATED: "相手あり",
  statusUndefined: "Undefined",
  emailSent: "[email] にパスワードのリセット用のメールが送信されました。",
  passwordDoesNotMatch: "パスワードが一致しません",
  invalidExistingPassword: "不正なパスワード",
  linkExpired: "このリンクは期限切れです。",
  gatewayError: "Authentic Indication ゲートウェイはオフラインです。お手伝いが必要な場合は、'support@authenticindication.com' までメールでお問い合わせください。",

  //Profile
  profileSecurity: "セキュリティ",
  resetPassword: "パスワードリセット",
  currentPassword: "現在のパスワード",
  newPassword: "新しいパスワード",
  confirmNewPassword: "新しいパスワード（確認）",
  passwordResetSuccess: "パスワードのリセットが成功しました",
  prefsUpdated: "デフォルト設定が更新されました",

  // First Login page
  firstLoginWelcome: "Authentic Indicationへようこそ!",
  firstLoginInstructions: "新しいパスワードを入力して確認してください。送信するとログアウトされ、新しいパスワードでログインする必要があります。",

  // Error Codes
  'ERR-001': "[header]の値が無効。",
  'ERR-002': "行目[line]: RIC コードが見つかりません。",
  //Error code 3 will be file/error specific and can have multiple results (no 1:1 translation mapping)
  'ERR-004': "行目[line]: [ric]: ユーザーまたはユーザーの所属機関が、このセキュリティで既に広告を出稿している。",
  'ERR-005': "行目[line]: [ric]: 同じ銘柄で複数の広告を出稿することはできません。",
  'ERR-006': "行目[line]: [ric]: [minimumShareQuantity]株数以上の[direction]で [shareQuantity]株数",
  'ERR-007': "行目[line]: [ric]: [value]の最小量が[minimum]と[maximum]の間にない。",
  'ERR-008': "行目[line]: [ric]: [value]の最小量が[minimum]と[maximum]の間にない。",
  'ERR-009': "行目[line]: [ric]: [value]の信頼性ハードルが[minimum]と[maximum]の間にない。",
  'ERR-010': "行目[line]: [ric]: [value]のキャンセルトリガーが[minimum]と[maximum]の間にない。",
  'ERR-011': "行目[line]: [ric]: [value]のインデックス相対オフセットが[minimum]から[maximum]の間に入っていない。",
  'ERR-012': "行目[line]: [ric]: AIプラットフォームの営業時間外 - ",
  'ERR-013': "行目[line]: データフィールドの数がヘッダーの数と一致しません。",
  'ERR-014': "行目[line]: [ric]: 取引所は本日終了",
  'RESTORE-ERR-01': "[ric]: [minimumShareQuantity]株数以上の[direction]で [shareQuantity]株数",
  'RESTORE-ERR-02': "[ric]: ユーザーまたはユーザーの所属機関が、このセキュリティで既に広告を出稿している。",
  'RESTORE-ERR-03': "[ric]: AIプラットフォームの営業時間外 - ",
  'RESTORE-ERR-04': "RIC コードが見つかりません",
  'RESTORE-ERR-05': "[ric]: 取引所は本日終了",
  'MARKET-ERROR-001': "キャンセル, 不正確な市場データ",
  'MARKET-ERROR-002': "キャンセル, 不正確な市場データ",
  'MARKET-ERROR-003': "キャンセル, 不正確な市場データ",
  'MARKET-ERROR-004': "キャンセル, 閑散市況",
  'MARKET-ERROR-005': "キャンセル, 閑散市況",
  'MARKET-ERROR-006': "キャンセル, 閑散市況",
  'MARKET-ERROR-007': "キャンセル, 不正確な市場データ",
  'MARKET-ERROR-012': "キャンセル, 不正確な市場データ",
  'MARKET-ERROR-013': "キャンセル, 不正確な市場データ",
  'CANCEL-TRIGGER-001': "キャンセル, キャンセルトリガー価格",
  'CANCEL-TRIGGER-002': "キャンセル, 指数基準でのキャンセル",
  'ADVERT-ERROR-001': "弊社に%s1のIOIはすでに存在します",
  'ADVERT-ERROR-002': "キャンセルまたは相手ありIOIは変更できません",
  'ADVERT-ERROR-003': "休止されてないIOIは再開できません",
  'ADVERT-ERROR-004': "株数不足、%s1以上の株数でIOI送信できます",
  'ADVERT-ERROR-005': "残りの株数でIOIできません",
  'ADVERT-ERROR-006': "信頼性フィルター無効な入力",
  'ADVERT-ERROR-007': "キャンセルまたは相手ありIOIは休止できません",
  'ADVERT-ERROR-008': "%s1のIOIはすでに存在します",
  'ADVERT-ERROR-010': "%s1の取引所は本日終了",
  'ADVERT-ERROR-011': "ブローカーを選択して下さい",

  // Validations
  requiredField: 'このフィールドは必須です',
  minValue: '許容される最小値は {min} です',
  maxValue: '許容される最大値は {max} です',
};

export default translations;
